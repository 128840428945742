import { reactive, ref } from 'vue';
const isUserPermissionFormUpdated = ref(false);
const userPermissionsForm = ref({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    priveleges: '',
    preference: '',
    access: '',
    userGroupMemberships: [ ]
});

export default () => {

    return {
        userPermissionsForm,
        isUserPermissionFormUpdated
    };
};
